/**
 * IMPORTS
 */

import React from 'react';
import { Text, Stack } from '@chakra-ui/react';
import Lottie from 'react-lottie';

import FullHeightFlex from './FullHeightFlex';
import MinHSpacer from './MinHSpacer';

import loaderAnimation from '../../../src/assets/dpstampede-loader.json';

/**
 * CORE
 */

interface SpinningWheelProps {
  text: string;
  centerLabel?: string;
}

function SpinningWheel({ text, centerLabel }: SpinningWheelProps) {
  return (
    <FullHeightFlex direction="column">
      <MinHSpacer />
      <Stack align="center">
        <Lottie
          width={175}
          height={175}
          options={{
            loop: true,
            autoplay: true,
            animationData: loaderAnimation,
          }}
          style={{ marginBottom: '-2rem' }}
        />
        <Text>
          {text}
        </Text>
      </Stack>
      <MinHSpacer />
    </FullHeightFlex>
  );
}
export default SpinningWheel;
